/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useEffect, useRef } from 'react';

import Portal from '../components/atoms/Portal';
import useComponentLoading from '../hooks/useComponentLoading';
import { mountableReactPages } from './mountableReactPages';
import {
    buildReactPageSelector,
    getReactComponentData,
    getReactPageFromDom,
} from './reactMounting';

const getPageFromDOM = (pageName: string) => {
    const domNode = document.querySelector<HTMLElement>(buildReactPageSelector(pageName));
    if (!domNode) {
        return { domNode: null, props: null };
    }
    const pageData = getReactComponentData(domNode);
    return { ...pageData, domNode };
};

const PageComponentLoader = ({ pageName }: { pageName: string }) => {
    const pageModule = mountableReactPages[pageName];
    const PageComponent = useComponentLoading(pageName, pageModule);

    const { props: pageProps, domNode: pageDomNode } = getPageFromDOM(pageName);

    if (!PageComponent || !pageDomNode) {
        return null;
    }

    return (
        <Portal domNode={pageDomNode}>
            <PageComponent {...pageProps} />
        </Portal>
    );
};

const ReactPageLoader: React.FC<React.PropsWithChildren<unknown>> = () => {
    const reactPageName = useRef(getReactPageFromDom());

    const pageName = reactPageName.current;

    useEffect(() => {
        console.logDev('Trying to mount React page:', pageName);

        return () => {
            console.logDev('Trying to unmount React page:', pageName);
        };
    }, [pageName]);

    if (!pageName) {
        console.errorDev('Page name is misssing', pageName);
        return null;
    }

    return <PageComponentLoader pageName={pageName} />;
};

export default ReactPageLoader;
