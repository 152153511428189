/* eslint-disable no-console */
import type { ComponentType } from 'react';

const loading = new Map<string, Promise<ComponentType<unknown> | null>>();
const loaded = new Map<string, ComponentType<React.PropsWithChildren<unknown>>>();

export interface ComponentModule<T> {
    default: ComponentType<React.PropsWithChildren<T>>;
}

export const loadType = <T extends unknown = unknown>(
    promise: Promise<ComponentModule<T>>,
    componentName: string,
    loadOptions?: {
        isPreLoad: boolean;
    },
) => {
    const loadedCmp = loaded.get(componentName);
    // instant resolve promise if already loaded
    if (loadedCmp) {
        return Promise.resolve(loadedCmp);
    }

    const loadingCmp = loading.get(componentName);
    // get the same promise if there are multiple calls for the same type
    if (loadingCmp) {
        return loadingCmp;
    }

    const loadingPromise = promise
        .then((response) => {
            const responseCmp = response.default as ComponentType<unknown>;
            if (!loaded.get(componentName)) {
                loaded.set(componentName, responseCmp);
            }
            loading.delete(componentName);
            console.logDev(`componentLoader - loaded: ${componentName}`);
            return responseCmp;
        })
        .catch((reason) => {
            loading.delete(componentName);
            if (!loadOptions?.isPreLoad) {
                console.error(`componentLoader - could not load ${componentName}`);
                console.logDev(reason);
            }
            return null;
        });

    loading.set(componentName, loadingPromise);

    return loading.get(componentName);
};

export const getCachedComponent = <T>(componentName: string) => {
    if (!componentName) return undefined;
    return loaded.get(componentName) as ComponentType<React.PropsWithChildren<T>>;
};

export default loaded;
