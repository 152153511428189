import { appConfig } from '../../../appConfig';

export const ensureTrailingSlash = (url: string) => {
    return url.replace(/(\/*)?$/, '/');
};

export const ensureLeadingSlash = (url: string) => {
    return url.replace(/^(\/*)?/, '/');
};

type LoginServerIntent = 'login' | 'create';
type LoginServerUserType = 'company' | 'private';
type LoginServerPrompt = 'none' | 'login';

const getLoginParams = ({
    intent,
    userType,
    prompt,
    returnUrl,
}: {
    intent?: LoginServerIntent;
    userType?: LoginServerUserType;
    prompt?: LoginServerPrompt;
    returnUrl?: string;
}) => {
    const queryString = new URLSearchParams();
    if (intent) {
        queryString.set('intent', intent);
    }

    if (userType) {
        queryString.set('loginType', userType);
    }

    if (prompt === 'login') {
        queryString.set('prompt', prompt);
    }

    if (returnUrl) {
        queryString.set('returnUrl', returnUrl);
    }

    return queryString;
};

export const getCreateAccountUrl = (userType: LoginServerUserType = 'private') => {
    const { loginUrl } = appConfig.coopSettings;
    const queryString = getLoginParams({
        intent: 'create',
        userType,
    });

    return `${loginUrl}?${queryString.toString()}`;
};

export const getLoginUrl = (
    userType: LoginServerUserType = 'private',
    prompt?: LoginServerPrompt,
    returnUrl?: string,
) => {
    const { loginUrl } = appConfig.coopSettings;
    const queryString = getLoginParams({
        intent: 'login',
        userType,
        prompt,
        returnUrl,
    });

    return `${loginUrl}?${queryString.toString()}`;
};

export const getLoginUrlWithReturnUrl = (
    userType: LoginServerUserType = 'private',
    prompt?: LoginServerPrompt,
    stayOnPage?: boolean,
) => {
    const defaultReturnUrl =
        userType === 'company'
            ? undefined // on the backend in OpenIdController we always return B2B user to the B2B Handla page after successful login, so we skip this returnUrl for them
            : appConfig.coopSettings.myCoop.myCoopUrl;

    const url = getLoginUrl(userType, prompt, stayOnPage ? window.location.href : defaultReturnUrl);
    return url;
};

export const addQueryParamsToUrl = (
    url: string,
    paramValues: Record<string, string | undefined>,
) => {
    const [path, currQueryString = ''] = url.split('?');

    const removedPotentialLastSlashFromPath =
        path.slice(-1) === '/' ? path.slice(0, path.length - 1) : path;

    const urlSearchParams = Object.entries(paramValues).reduce((params, [key, value]) => {
        if (value && value?.length > 0) {
            params.set(key, value);
        }
        return params;
    }, new URLSearchParams(currQueryString));

    const correctEnding = urlSearchParams.toString() ? `?${urlSearchParams.toString()}` : '/';

    return `${removedPotentialLastSlashFromPath}${correctEnding}`;
};
