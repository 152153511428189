import { useEffect } from 'react';

import type { ComponentModule } from '../init/componentLoader';
import { getCachedComponent, loadType } from '../init/componentLoader';
import { useAsyncDispatcher } from './useAsyncDispatcher';

const loadComponent = async (
    componentName: string,
    componentModule?: () => Promise<ComponentModule<unknown>>,
) => {
    if (!componentName || !componentModule) return null;
    return loadType(componentModule(), componentName);
};

const useComponentLoading = (
    componentName: string,
    componentModule?: () => Promise<ComponentModule<unknown>>,
) => {
    const { result: Component, execute: loadComponentType } = useAsyncDispatcher(
        (_componentName: string, _componentModule?: typeof componentModule) =>
            loadComponent(_componentName, _componentModule),
        {
            initialResultState: getCachedComponent(componentName),
        },
    );

    useEffect(() => {
        loadComponentType(componentName, componentModule);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loadComponentType, componentName]);

    return Component;
};

export default useComponentLoading;
