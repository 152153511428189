import * as React from 'react';

import ReactAppWrapper from './ReactAppWrapper';
import ReactComponentLoader from './ReactComponentLoader';
import ReactEpiComponentLoader from './ReactEpiComponentLoader';
import ReactEpiPageLoader from './ReactEpiPageLoader';
import ReactPageLoader from './ReactPageLoader';

const ReactApp = () => {
    return (
        <ReactAppWrapper>
            <ReactPageLoader />
            <ReactEpiPageLoader />
            <ReactComponentLoader />
            <ReactEpiComponentLoader />
        </ReactAppWrapper>
    );
};

export default ReactApp;
