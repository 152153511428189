import * as React from 'react';
import { createRoot } from 'react-dom/client';

import globalInvokes from './globalFunctionInvokes';
import ReactApp from './microApps/common/init/ReactApp';

globalInvokes();

const reactAppHtml = document.querySelector<HTMLElement>('.js-reactApp');
const componentElement = React.createElement(ReactApp);

const root = createRoot(reactAppHtml!);
root.render(componentElement);
