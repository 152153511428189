import type { PriceData } from '../microApps/common/models/priceData/priceData';
import { PriceType } from '../microApps/common/models/priceData/priceData';
import { isNumber, isNumeric } from '../microApps/common/utils/numberUtils';

export namespace Helpers {
    /**
     * One day in javascript time
     */
    export const ONE_DAY = 86400000; // 24 hours * 60 minutes * 60 seconds * 1000 millisconds = 86400000

    const emailReg = /^[\w.\-+_]+@([.\-a-z0-9]+\.[a-z]{2,10})$/;
    export const validateEmail = (email: string) => {
        if (emailReg.test(email)) {
            return true;
        }
        return false;
    };

    export const getParameterByName = (name: string, input?: string) => {
        let url = input;
        if (!url) url = window.location.href;
        return decodeURIComponent(
            url.replace(
                new RegExp(
                    `^(?:.*[&\\?]${encodeURIComponent(name).replace(
                        // eslint-disable-next-line no-useless-escape
                        /[\.\+\*]/g,
                        '\\$&',
                    )}(?:\\=([^&]*))?)?.*$`,
                    'i',
                ),
                '$1',
            ),
        );
    };

    /**
     * Returns the name of a month (gmt + 1)
     * @param date either the date or a month number (0 based).
     * @param short if you want 'jan' insted of 'januari'.
     */
    export const getMonthName = (date: Date | number, short?: boolean) => {
        const months = [
            'januari',
            'februari',
            'mars',
            'april',
            'maj',
            'juni',
            'juli',
            'augusti',
            'september',
            'oktober',
            'november',
            'december',
        ];
        const monthsShort = [
            'Jan',
            'Feb',
            'Mars',
            'April',
            'Maj',
            'Juni',
            'Juli',
            'Aug',
            'Sep',
            'Okt',
            'Nov',
            'Dec',
        ];

        let monthIndex: number;
        if (isNumeric(date) && date >= 0 && date <= 11) {
            monthIndex = <number>date;
        } else if (date instanceof Date) {
            const localDate = new Date(
                date.valueOf() + (date.getTimezoneOffset() + 120) * 60 * 1000,
            );
            monthIndex = localDate.getMonth();
        } else {
            return '';
        }

        return short ? monthsShort[monthIndex] : months[monthIndex];
    };

    /**
     * Returns the name of a day
     * @param date either the date or a day number (0 based).
     * @param short if you want 'Mån' insted of 'Måndag'.
     */
    export const getDayName = (date: Date | number, short?: boolean) => {
        const weekdays = ['söndag', 'måndag', 'tisdag', 'onsdag', 'torsdag', 'fredag', 'lördag'];
        const weekdaysShort = ['sön', 'mån', 'tis', 'ons', 'tor', 'fre', 'lör'];

        let dayIndex: number;
        if (isNumeric(date) && date >= 0 && date <= 6) {
            dayIndex = <number>date;
        } else if (date instanceof Date) {
            // time zone adjustment do not touch
            const localDate = new Date(
                date.valueOf() + (date.getTimezoneOffset() + 120) * 60 * 1000,
            );
            dayIndex = localDate.getDay();
        } else {
            return '';
        }

        return short ? weekdaysShort[dayIndex] : weekdays[dayIndex];
    };

    export const dateIsToday = (date: Date) => {
        const today = new Date();
        return (
            today.getDate() === date.getDate() &&
            today.getMonth() === date.getMonth() &&
            today.getFullYear() === date.getFullYear()
        );
    };

    export const dateIsTomorrow = (date: Date) => {
        const tommorow = new Date();
        tommorow.setDate(tommorow.getDate() + 1);
        return datesIsSameDay(date, tommorow);
    };

    export const dateIsYesterday = (date: Date) => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return datesIsSameDay(date, yesterday);
    };

    export const datesIsSameDay = (date1: Date, date2: Date) => {
        return (
            date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear()
        );
    };

    /**
    formats a date by date, month format
    */
    export const formatDateDdMmm = (date: Date, shortMonth?: boolean) => {
        if (!date) return '';
        const localDate = new Date(date.valueOf() + (date.getTimezoneOffset() + 120) * 60 * 1000);

        const day = localDate.getDate();

        return `${day} ${getMonthName(localDate, shortMonth)}`;
    };

    /**
     * formats date to yyyy-mm-dd, working with gmt + 1
     * @param date
     */
    export const formatDateYyyyMmDd = (date: Date) => {
        if (!date) return '';
        const localDate = new Date(date.valueOf() + (date.getTimezoneOffset() + 120) * 60 * 1000);
        const day = localDate.getDate();
        const month = localDate.getMonth() + 1;
        const year = localDate.getFullYear();
        let result = `${year}-`;
        if (month < 10) result += '0';
        result += `${month}-`;
        if (day < 10) result += '0';
        result += day;
        return result;
    };

    /**
     * Simply takes the date part out of the Date and returns it as a string
     * @param date 2021-05-01T01:00:00+02:00
     * @returns 2021-05-01
     */
    export const formatDateYyyyMmDdIgnoringTimeZone = (date: Date) => {
        const month = `${date.getMonth() + 1}`;
        const day = `${date.getDate()}`;
        const year = date.getFullYear();

        const formattedMonth = month.length < 2 ? `0${month}` : month;
        const formattedDay = day.length < 2 ? `0${day}` : day;

        return `${year}-${formattedMonth}-${formattedDay}`;
    };

    /**
     * gets the current day, setting hours and seconds to 00
     * @param date
     */
    export const getToday = () => {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        return date;
    };

    /**
     * Gets only date from datetime, basically sets hours, seconds etc to 0.
     * @param date
     */
    export const getDatePart = (date: Date | number) => {
        const newDate = new Date(date);
        newDate.setHours(0, 0, 0, 0);
        return newDate;
    };

    export const containsOnlyMonthInDate = (date: Date, dates: Date[]) => {
        const currentMonth = date.getMonth();
        return dates.findIndex((d) => d.getMonth() !== currentMonth) === -1;
    };

    export const getDaysForMonthInDate = (date: Date) => {
        const year = date.getFullYear();
        const month = date.getMonth();

        return new Date(year, month + 1, 0).getDate();
    };

    export const getDaysLeftForMonthInDate = (date: Date) => {
        const daysForMonth = getDaysForMonthInDate(date);
        return daysForMonth - date.getDate();
    };

    /**
     * Will return an array of dates for the months between and including startDate and endDate,
     * with the date always being the first date of the month
     * @param startDate 2021-03-05
     * @param endDate 2021-05-07
     * @returns [2021-03-01, 2021-04-01, 2021-05-01]
     */
    export const getMonthsInInterval = (startDate: Date, endDate: Date): Date[] => {
        const startYear = startDate.getFullYear();
        const startMonth = startDate.getMonth();
        const endYear = endDate.getFullYear();
        const endMonth = endDate.getMonth();

        const yearDiff = endYear - startYear;
        const monthDiff = endMonth - startMonth + 1;
        const months = yearDiff * 12 + monthDiff;

        const monthsNumbers = Array.from({ length: months }, (_, i) => i);

        return monthsNumbers.map(
            (monthNumber) =>
                new Date(Date.UTC(startDate.getFullYear(), startMonth + monthNumber, 1)),
        );
    };

    /**
     * E.g '12:00 idag', '12:00 imorgon', '12:00 den 16 februari'
     * if 'reversed': i.e. 'idag 12:00', 'imorgon 12:00', '12:00 den 16 februari'
     * @param dateToFormat
     */
    export const formatDateToHHDate = (date: Date | number, reverse: boolean = false) => {
        const dateToFormat = date instanceof Date ? date : new Date(date);
        const TODAY_TEXT: string = 'idag';
        const TOMORROW_TEXT: string = 'imorgon';

        const formattedTime = Helpers.formatHHmm(dateToFormat);

        const today = new Date();
        if (dateToFormat.getDate() === today.getDate()) {
            return reverse ? `${TODAY_TEXT} ${formattedTime}` : `${formattedTime} ${TODAY_TEXT}`;
        }

        if (dateToFormat.getDate() === today.getDate() + 1) {
            return reverse
                ? `${TOMORROW_TEXT} ${formattedTime}`
                : `${formattedTime} ${TOMORROW_TEXT}`;
        }

        return `${formattedTime} den ${dateToFormat.getDate()} ${Helpers.getMonthName(
            dateToFormat.getMonth(),
        )}`;
    };

    export const formatHHmm = (date: Date) => {
        const formatTimeTwoDigts = (n: number) => {
            return n < 10 ? `0${n}` : n;
        };

        const hours = formatTimeTwoDigts(date.getHours());
        const minutes = formatTimeTwoDigts(date.getMinutes());
        return `${hours}.${minutes}`;
    };

    /**
     * Safely converts values to numbers and adds them together. Falsy values defaults to 0.
     * @param ...args values to add together.
     * @return sum of ...args
     */
    export const safeStringAddition = (
        ...args: (string | number | null | undefined | object)[]
    ): number =>
        args
            .filter(Boolean)
            .filter((item) => typeof item !== 'object')
            .map((item) => parseFloat(item.toString()))
            .map((x) => x || 0)
            .reduce((prev, acc) => prev + acc);

    /**
     * @param x, number to format
     * @param separator, default separator is space
     */

    export const formatNumber = (
        numberString: string,
        separator?: string,
        decimalSeparator?: string,
    ) => {
        let sep = separator;
        let decSep = decimalSeparator;

        if (!numberString) return numberString;
        if (!sep) sep = ' ';
        if (!decSep) decSep = ',';

        let result = numberString.toString().replace(/\B(?=(\d{3})+(?!\d))/g, sep);
        if (result.indexOf('-') === 0) result = `- ${result.slice(1)}`;

        return result.replace('.', decSep);
    };

    const PRICE_DECIMAL_SEPARATOR = ',';

    type DecimalFormatMode = 'doubleZero' | 'dash' | 'hideRest';

    /**
     * If showDoubleZero == "doubleZero": Formats a number i.e. from 1.54 to 1,54, and 1.00 to 1,00
     * If showDoubleZero == "dash": Formats a number i.e. from 1.54 to 1,54, and 1.00 to 1:-
     * If showDoubleZero == "hideRest": Formats a number i.e. from 1.54 to 1,54, and 1.00 to 1 - so. If there is a rest, it will be shown anyway.
     *
     * Dash should only be used in promotion splashes
     */
    export const toEcommercePrice = (
        price: number | undefined,
        noDecimalMode: DecimalFormatMode = 'doubleZero',
    ) => {
        let decimalMode = noDecimalMode;
        let hideDecimals = false;
        if (price == null) {
            return '';
        }

        let result = truncateDecimalsWithSeparator(price, 2, ' ', PRICE_DECIMAL_SEPARATOR);
        if (price >= 1000) {
            result = insertEveryNCharacters(result, 3, ' ');
            decimalMode = 'hideRest'; // intended by designers: always hide decimals if price is counted in thousands
        }

        const hasNoRest = result.indexOf(`${PRICE_DECIMAL_SEPARATOR}00`) > -1;

        if (hasNoRest) {
            if (decimalMode === 'dash') {
                result = result.replace(`${PRICE_DECIMAL_SEPARATOR}00`, ':-');
            }

            if (decimalMode === 'doubleZero') {
                // already has double zero, do nothing
            }

            if (decimalMode === 'hideRest') {
                hideDecimals = true;
            }
        }

        // only hide if the is no rest, otherwise show the rest as normal
        if (hasNoRest && hideDecimals) {
            result = result.slice(0, result.indexOf(PRICE_DECIMAL_SEPARATOR));
        }

        return result;
    };

    /**
     * Formats a number i.e. from 1.54 to 1:54 kr, and 1.00 to 1:00 kr.
     */
    export const toEcommercePriceWithCurrency = (
        price: number | null | undefined,
        noDecimalMode: DecimalFormatMode = 'doubleZero',
    ) => {
        if (price == null) {
            return '';
        }

        let result = Helpers.toEcommercePrice(price, noDecimalMode);

        result += ' kr';
        return result;
    };

    export const truncateWithoutEmptyDecimals = (num: number | string) =>
        truncateDecimalsWithSeparator(num, 2).replace(',00', '');

    export const isMmkid = (mmkid: string) => {
        return /^(\d{7})$/.test(trimAndRemoveWhiteSpaces(mmkid));
    };

    export const trimAndRemoveWhiteSpaces = (s: string) => {
        return s.trim().replace(/\s/g, '');
    };

    export const isNullOrWhiteSpace = (s: string) => {
        return !s || s.length === 0 || !s.trim();
    };

    // TODO this is NIGHTMARE, this needs to be refactored and write tests to it, it's unreadable
    export const truncateDecimalsWithSeparator = (
        num: number | string,
        digits: number,
        separator?: string,
        decimalSeparator?: string,
    ) => {
        if (typeof num === 'number') {
            // eslint-disable-next-line no-param-reassign
            num = num.toFixed(2); // handle numbers in scientific notifcation, like 10.2345E23
        }
        const numS = num.toString().replace(',', '.');
        let decPos = numS.indexOf('.');
        const substrLength = decPos === -1 ? numS.length : 1 + decPos + digits;
        const trimmedResult = numS.substr(0, substrLength);
        let finalResult = Number.isNaN(trimmedResult) ? '0' : trimmedResult;

        if (decPos !== -1) {
            let s = `${trimmedResult}`;
            decPos = s.indexOf('.');
            let decLength = s.length - decPos;

            while (decLength <= digits) {
                s += '0';
                decPos = s.indexOf('.');
                decLength = s.length - decPos;
            }
            finalResult = s;
        }

        return formatNumber(finalResult, separator, decimalSeparator);
    };

    export const alphabeticSortAscPredicate = (a: string, b: string) => {
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
    };

    /**
     * Slugifies a string, note that this might not be the same slugification method as backend
     */
    export const slugify = (string: string) => {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
        const p = new RegExp(a.split('').join('|'), 'g');

        return (
            string
                .toString()
                .toLowerCase()
                .replace(/\s+/g, '-') // Replace spaces with -
                .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
                .replace(/&/g, '-and-') // Replace & with 'and'
                // eslint-disable-next-line no-useless-escape
                .replace(/[^\w\-]+/g, '') // Remove all non-word characters
                // eslint-disable-next-line no-useless-escape
                .replace(/\-\-+/g, '-') // Replace multiple - with single -
                .replace(/^-+/, '') // Trim - from start of text
                .replace(/-+$/, '')
        ); // Trim - from end of text
    };

    export const capitalizeFirstLetter = (string: string) => {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    export const capitalize = (string: string) => {
        if (!string) return string;
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    export const capitalizeWords = (string: string) => {
        if (!string) return null;

        const splitBy = ' ';

        const words = string.split(splitBy);
        return words.map(capitalize).join(splitBy);
    };

    /**
     * Sort array of objects by property
     * Returns a sort function
     * Example : myArrayOfObjects.sort(Helpers.by('name'));
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    export const by =
        <T>(property: keyof T) =>
        (a: T, b: T) =>
            // eslint-disable-next-line no-nested-ternary
            a[property] > b[property] ? 1 : a[property] < b[property] ? -1 : 0;

    export const insertEveryNCharacters = (input: string, every: number, separator: string) => {
        const regex = new RegExp(`\\B(?=(\\d{${every}})+(?!\\d))`, 'g');
        const result = input.replace(regex, separator);

        return result;
    };

    /**
     * Format number for points and amount
     * 10000 > 10 000
     * 9999 > 9 999
     */
    export const formatMemberPointsAndAmount = (input: number) => {
        if (!input) return input;
        const result = insertEveryNCharacters(input.toString(), 3, ' ');
        return result;
    };

    /**
     * Swedish formatted amount
     * Numbers up to 9999 should not have a space, then the space should be every 3 chars.
     * 9000, 10 000, 100 000, 1 000 000
     */
    export const swedishAmount = (input: string) => {
        const resultWithoutSpaces = input.replace(' ', '');
        if (resultWithoutSpaces.length < 5) {
            return resultWithoutSpaces;
        }

        const result = insertEveryNCharacters(input, 3, ' ');

        return result;
    };

    /**
     * Swedish formatted phone number.
     * Code from https://codegolf.stackexchange.com/questions/195787/format-a-swedish-phone-number
     */
    // eslint-disable-next-line no-return-assign
    export const swedishPhoneNumber = (
        input: string,
        B = (I: number) => +input[I] && input.length - I > 4,
        A = (a: string) => a.includes(input[1]),
        b = B(0),
        // eslint-disable-next-line no-nested-ternary
        L = A('8') ? 2 : A('569') ? 4 : 3,
    ) =>
        (b
            ? ''
            : input.slice(
                  0,
                  // eslint-disable-next-line no-nested-ternary, no-param-reassign
                  B(L) ? L : (L = L > 3 ? (B(3) ? 3 : 2) : L > 2 ? (B(4) ? 4 : 2) : B(4) ? 4 : 3),
              )) +
        (b ? '' : '-') +
        input.slice(b ? 0 : L).replace(/./g, (e, i, T, U = `${e} `) =>
            // eslint-disable-next-line no-nested-ternary
            T[5]
                ? // eslint-disable-next-line no-nested-ternary
                  T[6]
                    ? // eslint-disable-next-line no-nested-ternary
                      T[7]
                        ? // eslint-disable-next-line eqeqeq
                          i == 2 || i == 5
                            ? U
                            : e
                        : // eslint-disable-next-line eqeqeq
                        i == 2 || i == 4
                        ? U
                        : e
                    : // eslint-disable-next-line eqeqeq
                    i == 1 || i == 3
                    ? U
                    : e
                : // eslint-disable-next-line eqeqeq
                i == 2
                ? U
                : e,
        );

    export const safeSwedishPhoneNumber = (input: string) => {
        if (!input) {
            return null;
        }

        try {
            return swedishPhoneNumber(input);
        } catch {
            return input;
        }
    };

    export const formatPostalCode = (postalCode: string) => {
        return (postalCode && postalCode.match(/.{1,3}/g)?.join(' ')) || '';
    };

    export const getPrice = (priceData: PriceData, priceType: PriceType) => {
        switch (priceType) {
            case PriceType.ExcludeTax:
                return priceData?.exclTaxPrice;
            case PriceType.IncludeTax:
            default:
                return priceData?.inclTaxPrice;
        }
    };

    /**
     * Always use price with tax for GA
     */
    export const getPriceForGa = (priceData: PriceData) => {
        return getPrice(priceData, PriceType.IncludeTax);
    };

    export const getPriceTypeText = (priceType: PriceType) => {
        switch (priceType) {
            case PriceType.ExcludeTax:
                return 'Exkl. moms';

            case PriceType.IncludeTax:
            default:
                return 'Inkl. moms';
        }
    };

    export const isZipCode = (zipCodeString: string) => {
        const possibleZipCode = zipCodeString.split(' ').join('');
        return possibleZipCode.length === 5 && isNumber(possibleZipCode);
    };
}
